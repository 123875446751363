///select2setup.js
import $ from 'jquery'
// import 'select2/dist/css/select2.css'
import 'select2'

/// if you don't have turnbolinks then use this:
//// document.addEventListener('DOMContentLoaded', () => { 
window.addEventListener('turbolinks:load', () => {
  $('.select2').select2({
    tags: true,
    tokenSeparators: [','],
    minimumInputLength: 3,
    minimumResultsForSearch: 10,
  });

  $('.select2.search_quote_client').select2({
    ajax: {
      url: '/admin/search_client',  // Ensure this route is correct
      type: 'GET',  // Ensure it's a GET request
      dataType: 'json',
      data: function (params) {
        console.log('Search term:', params.term);  // Debugging
        return {
          q: params.term  // The search term sent to the server
        };
      },
      processResults: function (data) {
        console.log('Results:', data);  // Debugging
        return {
          results: data.map(function(client) {
            return { id: client.id, text: client.name };
          })
        };
      },
    },
    placeholder: "All Clients",
    tags: true,
    tokenSeparators: [','],
    minimumInputLength: 3,
    minimumResultsForSearch: 10,
  });

  $('.select2.select_user_clients').select2({
    ajax: {
      url: '/admin/search_client?page=user_clients',  // Ensure this route is correct
      type: 'GET',  // Ensure it's a GET request
      dataType: 'json',
      data: function (params) {
        console.log('Search term:', params.term);  // Debugging
        return {
          q: params.term  // The search term sent to the server
        };
      },
      processResults: function (data) {
        console.log('Results:', data);  // Debugging
        return {
          results: data.map(function(client) {
            return { id: client.id, text: client.name };
          })
        };
      },
    },
    placeholder: "All Clients",
    tags: true,
    tokenSeparators: [','],
    minimumInputLength: 3,
    minimumResultsForSearch: 10,
  });

  $('.select2.select_user_transporters').select2({
    ajax: {
      url: '/admin/search_client?page=user_transporters',  // Ensure this route is correct
      type: 'GET',  // Ensure it's a GET request
      dataType: 'json',
      data: function (params) {
        console.log('Search term:', params.term);  // Debugging
        return {
          q: params.term  // The search term sent to the server
        };
      },
      processResults: function (data) {
        console.log('Results:', data);  // Debugging
        return {
          results: data.map(function(client) {
            return { id: client.id, text: client.name };
          })
        };
      },
    },
    placeholder: "All Transporters",
    tags: true,
    tokenSeparators: [','],
    minimumInputLength: 3,
    minimumResultsForSearch: 10,
  });

  
})